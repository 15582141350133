import React from "react"
import Layout from "../../components/layout"
import Banner from "../../components/Hyderabad/Banner"
import bgImage from "../../../static/assets/blogs/bestMobileappdevelopment.svg"
const BestMobileAppDevelopment = () => {
  return (
    <Layout className="page">
      <Banner
        heading="Best Mobile App Development Services in Hyderabad"
        bgImage={bgImage}
      />
    </Layout>
  )
}

export default BestMobileAppDevelopment
